<template>
    <b-modal
        id="ModalUpdateModule"
        size="lg"
        title="Modifier un module"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormModule :module="module" @saved="onSaved" />
    </b-modal>
</template>
<script>
import FormModule from "@/components/model/module/FormModule";
export default {
    components: {
        FormModule,
    },

    props: {
        module: { type: Object, required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalUpdateModule");
            this.$emit("saved");
        },
    },
};
</script>
