<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Module
                <small class="text-kalkin-2">
                    <b-icon-layout-wtf /> {{ module.name }}
                </small>
            </h1>
            <div>
                <b-button
                    class="mr-2"
                    size="sm"
                    variant="outline-secondary"
                    v-b-modal.ModalUpdateModule
                >
                    <b-icon-pencil /> Modifier le module
                </b-button>
                <b-button
                    size="sm"
                    variant="outline-danger"
                    v-b-modal.ModalDanger
                >
                    <b-icon-trash /> Supprimer le module
                </b-button>
            </div>
        </div>

        <div class="k-flex-col mx-3">
            <div>
                <b class="text-kalkin-2">
                    Description :
                </b>
                {{ module.description || "Non renseigné" }}
            </div>
            <div>
                <b class="text-kalkin-2">
                    Version :
                </b>
                {{ module.version }}
            </div>
            <div>
                <b class="text-kalkin-2">
                    État :
                </b>
                {{ module.state | moduleStateFromValue }}
            </div>
            <div class="k-flex-row-2">
                <b class="text-kalkin-2">
                    Configuration :
                </b>
                <div class="badge border">
                    Est configuré ?
                    <b-icon-check-lg v-if="hasConfig" variant="kalkin-2" />
                    <b-icon-x-lg v-else variant="danger" />
                </div>
                <div class="badge border">
                    Interface admin étendue :
                    <b class="text-kalkin-2">
                        {{ getConfigType("admin") }}
                    </b>
                </div>
                <div class="badge border">
                    Interface interne ?
                    <b class="text-kalkin-2">
                        {{ getConfigType("internal") }}
                    </b>
                </div>
                <div class="badge border">
                    Interface membre ?
                    <b class="text-kalkin-2">
                        {{ getConfigType("member") }}
                    </b>
                </div>
            </div>
            <div>
                <b class="text-kalkin-2">
                    Ajouté le :
                </b>
                {{ module.createdAt | dateFromISO }}
            </div>
        </div>

        <h3 class="k-flex-row mt-3">
            Utilisations du module
            <div class="k-flex-line" />
        </h3>

        <TableModuleProjects class="mx-3" :module-id="moduleId" />

        <ModalUpdateModule :module="module" @saved="fetchModule" />
        <ModalDanger
            message="Voulez-vous vraiment supprimer ce module?"
            @confirmed="deleteModule"
        />
    </div>
</template>
<script>
import { get as _get } from "lodash";
import TableModuleProjects from "@/components/model/module/TableModuleProjects";
import ModalUpdateModule from "@/components/model/module/ModalUpdateModule";
import ModalDanger from "@/components/modal/ModalDanger";

const CONFIG_TYPE = {
    form: "Formulaire dynamique",
    component: "Vue dédiée",
    none: "Aucune",
};

export default {
    components: {
        TableModuleProjects,
        ModalUpdateModule,
        ModalDanger,
    },

    data: function() {
        return {
            moduleId: this.$route.params.moduleId,
            module: {},
        };
    },

    computed: {
        hasConfig() {
            return Object.keys(_get(this.module, "config") || {}).length > 0;
        },
    },

    methods: {
        async fetchModule() {
            this.module = await this.$store.dispatch("admin/getModule", {
                moduleId: this.moduleId,
            });
            if (!this.module) {
                this.$router.push({ name: "NotFound" });
            }
        },

        async deleteModule() {
            await this.$store.dispatch("admin/deleteModule", {
                moduleId: this.module.id,
            });
            this.$router.push({ name: "AdminListModules" });
        },

        getConfigType(node) {
            return (
                CONFIG_TYPE[_get(this.module, `config.${node}.type`, "none")] ||
                "inconnu"
            );
        },
    },

    async beforeMount() {
        await this.fetchModule();
    },
};
</script>
