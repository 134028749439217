<template>
    <div class="k-flex-col">
        <b-form-group>
            <label> <b>Nom</b> <span class="text-danger">*</span> </label>
            <b-form-input
                v-model="localModule.name"
                :state="isNameValid"
                trim
            ></b-form-input>
            <b-form-invalid-feedback :state="isNameValid">
                Veuillez saisir le nom du module
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label> <b> Version </b> <span class="text-danger">*</span> </label>
            <b-form-input
                v-model="localModule.version"
                type="number"
                step="1"
            />
        </b-form-group>

        <b-form-group>
            <label> <b> Type </b> <span class="text-danger">*</span> </label>
            <b-form-select v-model="localModule.type">
                <b-form-select-option
                    v-for="type in allModuleTypes"
                    :key="type.value"
                    :value="type.value"
                >
                    {{ type.label }}
                </b-form-select-option>
            </b-form-select>
            <small class="text-secondary">
                <b-icon-question-circle-fill />
                {{ findModuleType(localModule.type).description }}
            </small>
        </b-form-group>

        <b-form-group>
            <label> <b> État </b> <span class="text-danger">*</span> </label>
            <b-form-radio-group
                v-model="localModule.state"
                :options="stateOptions"
            />
            <small class="text-secondary">
                <b-icon-question-circle-fill />
                {{ stateHelp[localModule.state] }}
            </small>
        </b-form-group>

        <b-form-group>
            <label> Description </label>
            <b-form-textarea
                v-model="localModule.description"
                :state="isDescriptionValid"
                trim
            ></b-form-textarea>
            <b-form-invalid-feedback :state="isDescriptionValid">
                La description est trop longue
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label>Configuration</label>
            <JSONTextArea
                v-model="configAsString"
                @validate="isConfigValid = $event"
                :rows="5"
                :max-rows="10"
            />
        </b-form-group>

        <b-button
            class="align-self-center"
            variant="kalkin-2"
            @click="save()"
            :disabled="!isValid"
        >
            <span v-if="isEditMode">
                <b-icon-check-circle /> Enregistrer les modifications
            </span>
            <span v-else> <b-icon-plus-circle /> Créer le module </span>
        </b-button>
    </div>
</template>

<script>
import Vue from "vue";
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import Modules from "@/model/modules";
import { mapGetters } from "vuex";

const defaultModule = {
    name: null,
    description: null,
    type: 0,
    version: 1,
    state: 0,
    config: null,
};

export default {
    components: {
        JSONTextArea,
    },

    props: {
        module: { type: Object, required: false },
    },

    data: function() {
        return {
            isEditMode: !!this.module,
            localModule: Vue.util.extend({}, this.module || defaultModule),
            isConfigValid: true,

            stateOptions: Modules.getModuleStateOptions(),
            stateHelp: Modules.getModuleStateHelp(),
        };
    },

    computed: {
        configAsString: {
            get() {
                return JSON.stringify(this.localModule.config);
            },
            set(value) {
                if (this.isConfigValid) {
                    this.localModule.config = JSON.parse(value);
                }
            },
        },

        isNameValid() {
            if (this.localModule.name === null) {
                return null;
            } else {
                return this.localModule.name !== "";
            }
        },

        isDescriptionValid() {
            if (this.localModule.description === null) {
                return null;
            } else {
                return this.localModule.description.length < 255;
            }
        },

        isValid() {
            return (
                this.isNameValid &&
                this.isDescriptionValid !== false &&
                this.isConfigValid
            );
        },
        ...mapGetters("config", ["allModuleTypes", "findModuleType"]),
    },

    methods: {
        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("admin/updateModule", {
                    ...this.localModule,
                    moduleId: this.localModule.id,
                });
            } else {
                await this.$store.dispatch(
                    "admin/createModule",
                    this.localModule,
                );
            }
            this.$emit("saved");
        },
    },

    async beforeMount() {
        await this.$store.dispatch("config/getModuleTypes");
    },
};
</script>

<style></style>
